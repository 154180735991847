import { LoadState } from "../../constants/enums";
import { IArtist, IArtistProfile, IArtistState, IArtistWiki } from "./artist.types";

export const initialArtist: IArtist = {
    artist_id: "",
    artist_name: "",
    channel_id: "",
    status: "",
}


export const defaultArtistWiki: IArtistWiki =
{
    artist_id: 0,
    artist_bio: "",
    artist_father_name: null,
    artist_mother_name: null,
    artist_dob: null,
    artist_birth_place: null,
    artist_city: null,
    artist_schooling: null,
    artist_and_college: null,
    artist_relationship_status: null,
    artist_genre: null,
    artist_insta_handle: null,
    artist_facebook_handle: null,
    additional_info: [
        {
            detail_key: "",
            detail_value: ""
        }
    ],
    status: "ACTIVE",
}
export const initialArtistList: IArtistProfile = {
    artist_id: "",
    channel_id: null,
    channel_name: null,
    artist_name: null,
    artist_small_img: null,
    artist_medium_img: null,
    artist_large_img: null,
    artist_xlarge_img: null,
    artist_xxlarge_img: null,
    artist_slug: null,
    status: "",
}

export const initialArtistState: IArtistState = {
    list: {
        data: [],
        totalRecords: 0,
        loading: LoadState.NotLoaded,
        error: null
    },
    singleArtist: {
        data: initialArtist,
        loading: LoadState.NotLoaded,
        error: null
    },
    artistProfile: {
        data: initialArtistList,
        loading: LoadState.NotLoaded,
        error: null
    },
    artistWiki: {
        data: defaultArtistWiki,
        loading: LoadState.NotLoaded,
        error: null
    }

}